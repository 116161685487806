<script setup lang="ts">
import type { ResponsiveImageType } from "vue-datocms";
import type { SplitSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

const props = defineProps<{ data: SplitSectionRecord }>();

const localePath = useLocalePath();

const itemList = computed(() => props.data.items.map((i) => {
  return {
    id: i.id,
    text: i.text,
    linkItem: getLinkForType(i.optLinkItem, localePath),
    image: i.image,
  };
}));
</script>

<template>
  <div class="gap-lg md:gap-3xl grid grid-cols-1 md:grid-cols-2">
    <div v-for="item in itemList" :key="item.id" class="gap-xl p-md md:p-xl flex flex-col bg-[#F3F1EC]">
      <div class="html-content">
        <div v-html="item.text" />
        <p v-if="item.linkItem.to">
          <NuxtLinkLocale :to="item.linkItem.to" :target="item.linkItem.target" class="text-primary underline">
            {{ item.linkItem.label }}
          </NuxtLinkLocale>
        </p>
      </div>

      <DatocmsImage v-if="item.image" :data="item.image.responsiveImage as ResponsiveImageType" object-fit="cover" class="rounded-sm" />
    </div>
  </div>
</template>

<style scoped>

</style>
